import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
//import CalendarModal from './CalendarModal';
//    text=Example+Google+Calendar+Event&details=More+help+see:+https://support.google.com/calendar/thread/81344786&dates=20201231T160000/20201231T170000
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Table from 'react-bootstrap/Table';
import { getAdapter } from 'axios';
import { getByTitle } from '@testing-library/react';


const FormWithLocalStorage = () => {


  // Initialize state with data from localStorage, if available
  //const [formData, setFormData] = useState(() => {
  //  const savedData = localStorage.getItem('formData');
  //  return savedData ? JSON.parse(savedData) : { testevent: 'hi', name: '', email: '', month: '', day: '', year: '', startTime: '', endTime: ''};
  //});

  // Handle form input changes
  //const handleChange3 = (event) => {
  //  const { name, value } = event.target;
    //if ({name} = 'year') 
    //{console.log('hello!')}
    //{
    //setFormData(prevState => ({
    //  ...prevState,
    //  [name]: 'test2',
    //}));}

    //else {
   // setFormData(prevState => ({
   //   ...prevState,
   //   [name]: value,
  //  }));
  //}
  //};

//const handleChange2 = (event) => {
//  const { name, value } = event.target;
  //if ({name} = 'year') 
  //{console.log('hello!')}
  //{
  //setFormData(prevState => ({
  //  ...prevState,
  //  [name]: 'test2',
  //}));}

  //else {
//  setFormData(prevState => ({
//    ...prevState,
//    [name]: value,
//  }));
//}

  // Save data to local storage whenever formData changes
  //useEffect(() => {
  //  if (formData) {
  //    localStorage.setItem('formData', JSON.stringify(formData));
  //  }
  //}, [formData]);


  const [inputs, setInputs] = useState({});
  const [todos, setTodos] = useState([]);
  const [items, setItems] = useState([]);


  //if (Array.isArray(items)) {
    //const filteredEvents = items.filter(event => event.someCondition);
  //} else {
    //console.log("events is not an array");
  //}




  //console.log(items)
  //const [isModalOpen, setIsModalOpen] = useState(false);


  let yearfiltered = '';
  let monthfiltered = '';
  let dayfiltered = '';

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
  
    let filteredValue = '';
   console.log(value)
   console.log(yearfiltered)
   console.log(monthfiltered)
   console.log(dayfiltered)
    //let title = '';


    //if (name == 'testevent') {title += value}
    //let existingTitle = localStorage.getItem('title');
    //existingTitle = existingTitle ? JSON.parse(existingTitle) : []; // Parse or initialize as empty array
    
    // Add the new year to the array
    //existingTitle.push(parseInt(title)); // Convert to number and push to the array

    // Save the updated array back to localStorage
    //localStorage.setItem('title', JSON.stringify(existingTitle)); 
    // localStorage.setItem('year',yearfiltered);

   // for (let i = 0; i < 4; i++) {
    //  if (name == 'year') {
     //    yearfiltered += value[i];
     //  }
   //  }

   if (name == 'year') {yearfiltered = value.substring(0,4)}
   if (name == 'year') {monthfiltered = value.substring(5,7)}
   if (name == 'year') {dayfiltered = value.substring(8,10)}


  //   for (let i = 5; i < 7; i++) {
   //   if (name == 'year') {
    //     monthfiltered += value[i];
    //   }
    // }
  //   let existingMonths = localStorage.getItem('month');
  //   existingMonths = existingMonths ? JSON.parse(existingMonths) : []; // Parse or initialize as empty array
     
     // Add the new year to the array
  //   existingMonths.push(parseInt(monthfiltered)); // Convert to number and push to the array
 
     // Save the updated array back to localStorage
  //   localStorage.setItem('month', JSON.stringify(existingMonths)); 
     //localStorage.setItem('month',monthfiltered);


  //   for (let i = 8; i < 10; i++) {
      // if (value[i].toLowerCase() !== 'f') {  // Checking for both lowercase and uppercase 'f'
   //   if (name == 'year') {
   //      dayfiltered += value[i];
   //    }
   //  }
   //  let existingDays = localStorage.getItem('day');
   //  existingDays = existingDays ? JSON.parse(existingDays) : []; // Parse or initialize as empty array
     
     // Add the new year to the array
   //  existingDays.push(parseInt(dayfiltered)); // Convert to number and push to the array
 
     // Save the updated array back to localStorage
   //  localStorage.setItem('day', JSON.stringify(existingDays)); 
     //localStorage.setItem('day',dayfiltered);



     // Create a new string excluding the letter 'f'
  for (let i = 0; i < value.length; i++) {
   // if (value[i].toLowerCase() !== 'f') {  // Checking for both lowercase and uppercase 'f'
   if (name == 'year' && value[i] !== '-') {
      filteredValue += value[i];
    }
  }
 // console.log(value)
//console.log(filteredValue)
  localStorage.setItem('testing',filteredValue);
  
   // setInputs(values => ({...values, [name]: filteredValue}))
  }


 
  let titleb = ''

  const handleChange2 = (event) => {
  //  const name = event.target.name;
  //  const value = event.target.value;
   // let testingarray='';
    //testingarray += value;
  

  //console.log(value)
  //localStorage.setItem('guess',testingarray)
  //setInputs(event.target.value);

   //let title = '';



   //for (let i = 0; i < m; i++) {
    // if (value[i].toLowerCase() !== 'f') {  // Checking for both lowercase and uppercase 'f'
  //  if (name == 'testevent') {
  //     titleb += value;
  //   }
   //}
 
   const name = event.target.name;
   const value = event.target.value;
  // let testingarray='';
   //testingarray += value;
 
 
 //console.log(value)
 //localStorage.setItem('guess',testingarray)
 //setInputs(event.target.value);
 
  //let title = '';
 
 
 
  //for (let i = 0; i < m; i++) {
   // if (value[i].toLowerCase() !== 'f') {  // Checking for both lowercase and uppercase 'f'
   if (name == 'testevent') {
      titleb = value;
    }
  //}
 



  }



  const handleChange3 = (event) => {
    const name = event.target.name;
    const value = event.target.value;
  




    let filteredValue2 = '';

    // Create a new string excluding the letter 'f'
 for (let i = 0; i < value.length; i++) {
  // if (value[i].toLowerCase() !== 'f') {  // Checking for both lowercase and uppercase 'f'
  if (name == 'startTime' && value[i] !== ':') {
     filteredValue2 += value[i];
   }
 

  }



 //console.log(value)
//console.log(filteredValue2)

 localStorage.setItem('testStartTime',filteredValue2);

 

  }


  const handleChange4 = (event) => {
    const name = event.target.name;
    const value = event.target.value;
  
    let filteredValue3 = '';


   for (let i = 0; i < value.length; i++) {
 if (name == 'endTime' && value[i] !== ':') {
  filteredValue3 += value[i];
}
 }
// console.log(value)
//console.log(filteredValue3)

 localStorage.setItem('testEndTime',filteredValue3);


  }

let arrayhello = []
const testing2 = JSON.parse(localStorage.getItem('testing'));
//let getdata223 = localStorage.getItem('users');
//let getdata99 = JSON.parse(getdata223);

useEffect(() => {
  let getdata223 = localStorage.getItem('table3');
  let getdata99 = JSON.parse(getdata223);
  setTodos(getdata99);

  let getdata1000 = localStorage.getItem('CalTable2');
  let getdata1001 = JSON.parse(getdata1000)
  setItems(getdata1001);


},[]);


let getdata1001 = localStorage.getItem('CalTable2');

 let pleaseworkforme = JSON.parse(getdata1001);
//console.log(pleaseworkforme)
//console.log(getdata22)
  

//console.log(new Date(2024, 11, 19))


   


  //arrayhello += getdata22


const maitai = []

const hello2 = { id: 1, name: 'John Doe', age: 30 }
const hello3 = { id: 2, name: 'John Doe', age: 30 }


function appendToLocalStorage(key, newRecord) {
  // Step 1: Retrieve current data from localStorage
  let existingData = localStorage.getItem(key);
  
  // Step 2: If data exists, parse it; otherwise, initialize it as an empty array
  existingData = existingData ? JSON.parse(existingData) : [];
  
  // Step 3: Append the new record to the existing data
  existingData.push(newRecord);
  
  // Step 4: Save the updated data back to localStorage as a JSON string
  localStorage.setItem(key, JSON.stringify(existingData));
}

function appendToStorage2(name, data) {
  // Get existing data from localStorage
  let old = localStorage.getItem(name);
  
  // If no existing data, initialize as an empty array
  if (old === null) old = "[]";
  
  // Parse the existing data
  let parsedOld = JSON.parse(old);

  // Add the new data to the array
  parsedOld.push(data);

  // Store the updated array back into localStorage
  localStorage.setItem(name, JSON.stringify(parsedOld));
}





  const handleClick = (event) => {
    const hello = localStorage.getItem('testing');
    const starterTest = localStorage.getItem('testStartTime');
    const enderTest = localStorage.getItem('testEndTime');
    const date = localStorage.getItem('date');
    const windowFeatures = 'width=800,height=50,scrollbars=yes,resizable=yes,menubar=no,status=no';
    const yeartest = localStorage.getItem('year')
    const monthtest = localStorage.getItem('month')
    const daytest = localStorage.getItem('day')
    
    //maitai.push(event.target.name)
  // window.open(`https://calendar.google.com/calendar/u/0/r/eventedit?text=${inputs.testevent}&details=Brought+to+you+by+chilzy.com&dates=${hello}T${starterTest}00/${hello}T${enderTest}00&add=${inputs.email}`, '_blank', windowFeatures);
  
 // const name = target.name;
 // const value = target.value;
 // let testingarray='';
 const hey = 
 appendToLocalStorage('table3', { id: Date.now(), testevent: inputs, starttime: starterTest, endtime: enderTest, date: hello, age: 30 });
 const heyhey = 
 appendToLocalStorage('CalTable', { title: inputs, start: inputs.year, end: inputs.year });



 appendToLocalStorage('CalTable2', { 'title': inputs, 'allDay': true, 'start': new Date(yeartest, monthtest, daytest), 'end': new Date(yeartest, monthtest, daytest) });


 console.log(titleb)

 //const name = event.target.name;
 //const value = event.target.value;
// let testingarray='';
 //testingarray += value;


//console.log(value)
//localStorage.setItem('guess',testingarray)
//setInputs(event.target.value);

//let title = '';


//let m = value.length

//for (let i = 0; i < m; i++) {
 // if (value[i].toLowerCase() !== 'f') {  // Checking for both lowercase and uppercase 'f'
 //if (name == 'testevent') {
 //   titleb += value;
 // }


 let existingTitle = localStorage.getItem('title');
 existingTitle = existingTitle ? JSON.parse(existingTitle) : []; // Parse or initialize as empty array
 
 // Add the new year to the array
 existingTitle.push((titleb)); // Convert to number and push to the array

 // Save the updated array back to localStorage
 localStorage.setItem('title', JSON.stringify(existingTitle)); 



 let existingYears = localStorage.getItem('year');
 existingYears = existingYears ? JSON.parse(existingYears) : []; // Parse or initialize as empty array
 
 // Add the new year to the array
 existingYears.push(parseInt(yearfiltered)); // Convert to number and push to the array

 // Save the updated array back to localStorage
 localStorage.setItem('year', JSON.stringify(existingYears)); 


 let existingMonths = localStorage.getItem('month');
 existingMonths = existingMonths ? JSON.parse(existingMonths) : []; // Parse or initialize as empty array
 
 // Add the new year to the array
 existingMonths.push(parseInt(monthfiltered)-1); // Convert to number and push to the array

 // Save the updated array back to localStorage
 localStorage.setItem('month', JSON.stringify(existingMonths)); 


 let existingDays = localStorage.getItem('day');
 existingDays = existingDays ? JSON.parse(existingDays) : []; // Parse or initialize as empty array
 
 // Add the new year to the array
 existingDays.push(parseInt(dayfiltered)); // Convert to number and push to the array

 // Save the updated array back to localStorage
 localStorage.setItem('day', JSON.stringify(existingDays)); 
 // localStorage.setItem('year',yearfiltered);
 // localStorage.setItem('year',yearfiltered);
 //const yeartest2 = "2025"; // Example year for testing
 //appendToStorage2('year', yeartest2);

 //appendToLocalStorage('table', { id: 2, name: 'Jane Smith', age: 25 });

 //localStorage.setItem('guessfinal2',JSON.stringify(hello2));

//appendToLocalStorage('year', 2025)
//appendToLocalStorage('month', 12)
//appendToLocalStorage('day', 10)

//let testerevent = ''
//testevent += 
//console.log(testevent)

//const name = event.target.name;
//const value = event.target.value;
// let testingarray='';
//testingarray += value;


//console.log(value)
//localStorage.setItem('guess',testingarray)
//setInputs(event.target.value);

//let title = '';


//let m = value.length

//for (let i = 0; i < m; i++) {
// if (value[i].toLowerCase() !== 'f') {  // Checking for both lowercase and uppercase 'f'
//if (name == 'testevent') {
//   title += value[i];
// }
//}
//let existingTitle = localStorage.getItem('title');
//existingTitle = existingTitle ? JSON.parse(existingTitle) : []; // Parse or initialize as empty array

// Add the new year to the array
//existingTitle.push((title)); // Convert to number and push to the array

// Save the updated array back to localStorage
//localStorage.setItem('title', JSON.stringify(existingTitle)); 
// localStorage.setItem('year',yearfiltered);

  }
  
 //let existingdata = localStorage.getItem('guessfinal2')
 //existingdata = JSON.parse(existingdata);
 //existingdata += hello3
//localStorage.setItem('guessfinal2',JSON.stringify(existingdata));
//localStorage.setItem('guess',{ date: JSON.stringify(existingdata)});



//const getdata = JSON.parse(getdata);
 // const closeModal = () => setIsModalOpen(false);

  // Handle form submission
 // const handleSubmit = (event) => {
  //  event.preventDefault();
 //   alert('Form submitted with data: ' + JSON.stringify(formData));
   // Optionally, clear localStorage after submit
  //   localStorage.removeItem('formData');
  //};

  //const [showIframe, setShowIframe] = useState(false);

  //const handleOpenIframe = () => {
   // setShowIframe(true);
//{formData.Event...}
//const fruits = ['Apple', 'Banana', 'Cherry']; 


//for (let i = 0; i < fruits.length; i++) { 
//	console.log(fruits[i]); 
//}

//const jimmy = jimbo => {
 // let fruits = ['Apple', 'Banana', 'Cherry']; 
 //   for (let item of jimbo) {
 //  fruits[item]
 //   }}

// const {testevent} = '10/1/2024';

const testHandle = (event) => {
  let contents = [];
  contents.push('heythere')

  localStorage.setItem('test', JSON.stringify(contents));
};

  //const { name, value } = event.target;
  //if ({name} = 'year') 
  //{console.log('hello!')}
  //{
  //setFormData(prevState => ({
   // ...prevState,
   // [name]: 'test2',
  //}));}

  //else {
//  setFormData(prevState => ({
//    ...prevState,
//    [name]: value,
//  }));
//}


// Handle submit - test append to local storage
const testappendButton = content => {

  let contents = [];
  contents.push('test')
  //for (let i = 0; i< contents.length; i++) {
  //  const item = animals[i];
  //  contents.push('hello');
  //  localStorage.setItem('test', JSON.stringify(content));

  //}
  localStorage.setItem('test', JSON.stringify(contents));
  //localStorage.setItem('test'), JSON.stringify(content);
}


// Handle button - remove local storage items:
const handleButton = (event) => {
  event.preventDefault();
  localStorage.removeItem('formDatahello')
  localStorage.removeItem('formData')
  localStorage.removeItem('formData2')
  localStorage.removeItem('formData3')
  localStorage.removeItem('formDataformData')
  localStorage.removeItem('tableData')
  localStorage.removeItem('test')
  localStorage.removeItem('testing')
  localStorage.removeItem('table3')
  localStorage.removeItem('year')
  localStorage.removeItem('month')
  localStorage.removeItem('day')
  localStorage.removeItem('title')






}


let willthisworkforme = localStorage.getItem('CalTable2')

  return (
    <div>
          <Form>


    <InputGroup className="mb-3">
      <InputGroup.Text id="basic-addon1">
    Event
      </InputGroup.Text>
      <Form.Control 
       placeholder="name@example.com"
       aria-label="Event"
       aria-describedby="basic-addon1"
       type="text"
       id="testevent"
       name="testevent"
       value={inputs.testevent}
       onChange={handleChange2}

      />
    </InputGroup>

{/*
    <div class="form-floating mb-3">
        <input
          type="text"
          id="testevent"
          name="testevent"
          value={inputs.testevent}
          onChange={handleChange2}
          class="form-control"
          placeholder="name@example.com"
        />
                <label for="testevent">Event</label>

      </div>

  */}
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Date</InputGroup.Text>
        <Form.Control
          placeholder="Username"
          aria-label="Username"
          aria-describedby="basic-addon1"
          type="date"
          id="year"
          name="year"
          value={inputs.year}
          onChange={handleChange}
        />
      </InputGroup>
    
    {/* 
      <div class="form-floating mb-3">
       
        <input
          type="date"
          id="year"
          name="year"
          value={inputs.year}
          onChange={handleChange}
          class="form-control"
          placeholder="name@example.com"

        />
         <label for="year">Date</label>
      </div>
      */}
      <InputGroup className="form-floating mb-3">
      <InputGroup.Text>Time</InputGroup.Text>
      <FloatingLabel controlId="floatingInputGrid" label="Start">

      <Form.Control aria-label="First name"  type="time"           id="startTime"
          name="startTime"
          value={inputs.startTime}
          onChange={handleChange3}
/>
        </FloatingLabel>
        <FloatingLabel controlId="floatingInputGrid" label="End">
      <Form.Control aria-label="Last name" type="time"   id="endTime"
          name="endTime"
          value={inputs.endTime}
          onChange={handleChange4}/>
      </FloatingLabel>
    </InputGroup>

    {/* 
      <div>
        <label htmlFor="startTime">Start Time:</label>
        <input
          type="time"
          id="startTime"
          name="startTime"
          value={inputs.startTime}
          onChange={handleChange3}
        />
      </div>
      <div>
        <label htmlFor="endTime">End Time:</label>
        <input
          type="time"
          id="endTime"
          name="endTime"
          value={inputs.endTime}
          onChange={handleChange4}
        />
      </div>
*/}


{/*
      <div>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={inputs.email}
          onChange={handleChange2}
        />
      </div>
*/}
      <Button variant="outline-dark"  onClick = {handleClick}>Submit</Button>

   

    </Form>

   <div>
    {testing2}
   </div>
{/*
   <ul className="todos">
        {todos.map((todo) => (
          <li key={todo.id}>
            <p>{todo.id} (Age: {todo.id})</p>
          </li>
        ))}
      </ul>

      */}    
{/*
<Table striped bordered hover>    
   <thead >
    <tr>
      <th>Date</th>
      <th>Event</th>
      <th>Start Time</th>
      <th>End Time</th>
    </tr>
    </thead>

</Table>
        
      
  

    
   <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>{testing2}</th>
          <th>hello</th>
          <th>Username</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>@fat</td>
        </tr>
        <tr>
          <td>3</td>
          <td colSpan={2}>Larry the Bird</td>
          <td>@twitter</td>
        </tr>
      </tbody>
    </Table>
 */}    
{/*
    <a href={`https://calendar.google.com/calendar/u/0/r/eventedit?text=${formData99.event}&details=Brought+to+you+by+chilzy.com&dates=${formData99.year}T${formData99.startTime}0000/${formData99.year}T${formData99.endTime}0000&add=${formData99.email}`} target='_blank'>


    <Button variant="secondary" onClick = {handleButton}>Remove local storage</Button>
    <Button variant="secondary" onClick = {testappendButton}>Test append to local storage</Button>

  */}


<Button variant="secondary" onClick = {handleButton}>Remove local storage</Button>


    {/* 
    <a href={`https://calendar.google.com/calendar/u/0/r/eventedit?text=${formData.event}&details=Brought+to+you+by+chilzy.com&dates=${formData.year}${formData.month}${formData.day}T${formData.startTime}0000/${formData.year}${formData.month}${formData.day}T${formData.endTime}0000&add=${formData.email}`}>
    
    
   
    
    <button onClick={handleOpenIframe}>Open External Content</button>

    {showIframe && (

   <iframe
        src={`https://calendar.google.com/calendar/u/0/r/eventedit?text=${formData.event}&details=Brought+to+you+by+chilzy.com&dates=${formData.year}${formData.month}${formData.day}T${formData.startTime}0000/${formData.year}${formData.month}${formData.day}T${formData.endTime}0000&add=${formData.email}`}

        width="600" 
        height="400" 
        title="External Content"
   ></iframe>
      )}
   
    <Button variant="secondary">Send Chilzy Request</Button>
    </a>


    <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FChicago&showPrint=0&mode=AGENDA&showTitle=0&showNav=0&showDate=0&showTabs=0&showTz=0&src=ZmFtaWx5MDI1MjMwODA3NjQxNzgzMDY0MTdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%239E69AF" style={{borderWidth:0, width:800, height:600, frameborder:0, scrolling:"no"}}></iframe>

    */} 
    </div>
  );
};
//}
export default FormWithLocalStorage;







