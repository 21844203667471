//import pleaseworkforme from './Form'
//import existingTitle from './Form'
let existingTitle = localStorage.getItem('title');
existingTitle = existingTitle ? JSON.parse(existingTitle) : [];

let existingYears = localStorage.getItem('year');
existingYears = existingYears ? JSON.parse(existingYears) : [];

let existingMonths = localStorage.getItem('month');
existingMonths = existingMonths ? JSON.parse(existingMonths) : [];

let existingDays = localStorage.getItem('day');
existingDays = existingDays ? JSON.parse(existingDays) : [];


export default 


[

  {
          'title': 'testing',
         'allDay': true,
          'start': new Date(2024, 11, 16),
         'end': new Date(2024, 11, 19)
       },
       {
        'title': existingTitle[0],
       'allDay': true,
        'start': new Date(existingYears[0], existingMonths[0], existingDays[0]),
       'end': new Date(existingYears[1], existingMonths[1], existingDays[1])
     } ,
     {
      'title': existingTitle[3],
     'allDay': true,
      'start': new Date(existingYears[2], existingMonths[2], existingDays[2]),
     'end': new Date(existingYears[3], existingMonths[3], existingDays[3])
   } 


  //  {
  //      'title': 'Long Event',
  //      'allDay': true,
  //      'start': new Date(2024, 11, 16),
  //      'end': new Date(2024, 11, 19)
  //    },
     
  //      {
  //          'title': 'Long Event',
  //          'start': new Date(2024, 11, 20,0,0,0),
  //          'end': new Date(2024, 11, 20,0,1,0)
  //        }
    
]