import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'
//import myEventsList from './myEventsList'
import items from './Form'
import { useState } from 'react'
import willthisworkforme from './Form'
import { monthsInQuarter } from 'date-fns/constants'
import myEventsList from './myEventsList'

const locales = {
  'en-US': enUS,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})


// Test 1: myEventsList array here instead of separate JS file
// Result: Pass
const testUno = [

  {
          'title': 'Long Event',
         'allDay': true,
          'start': new Date(2024, 11, 16),
         'end': new Date(2024, 11, 16)
       }
  //  {
  //      'title': 'Long Event',
  //      'allDay': true,
  //      'start': new Date(2024, 11, 16),
  //      'end': new Date(2024, 11, 19)
  //    },
     
  //      {
  //          'title': 'Long Event',
  //          'start': new Date(2024, 11, 20,0,0,0),
  //          'end': new Date(2024, 11, 20,0,1,0)
  //        }
    
]

// Test 2a: get year, month, day data from local storage and console log it
// first remove all other console logs
// Result: Pass
const year = JSON.parse(localStorage.getItem('year'))
const month = JSON.parse(localStorage.getItem('month'))
const day = JSON.parse(localStorage.getItem('day'))

console.log(year)
console.log(month)
console.log(day)


// Test 2b: add year, month, day to an array an console log it
// Result: Pass
let testdosb = []
testdosb.push(year, month, day)
console.log(testdosb)


// Test 3: repeat test 2b with having the keys for each value
// Result: Pass
let testtres = []
testtres.push({'year': year, 'month': month, 'day': day})
console.log(testtres)


// Test 4: have one start value in format 'start': new Date(2024, 11, 16),
// Result:
let testcuatro = []
testcuatro.push({'start': new Date(year, month, day)})
console.log(testcuatro)

// Test 5: create array with placeholder title, all day, and start/end dates. test in events
// Result: Pass
let testcinco = []
testcinco.push({
  'title': 'Long Event',
 'allDay': true,
  'start': new Date(year, month, day),
 'end': new Date(year, month, day)
})
console.log(testcinco)


// Test 6: test new date: 4/13/2026
// Result: Fail 
// Reason: 1) data shows realtime in local storage but need to refresh for calendar to pick up new data, 2) data is off by one month ie: entering 4/13/26 in form results in 5/13/26 data in calendar


// Test 7: fix 6.2 error by subtracting one from month
// Result: Pass
let testsiete = []
testsiete.push({
  'title': 'Long Event',
 'allDay': true,
  'start': new Date(year, month - 1, day),
 'end': new Date(year, month - 1, day)
})
console.log(testsiete)


// Test 8: manually add multiple year data from local storage array
// Result: Pass. Commenting this out as was causing error
let testocho = []
//testocho.push({
//  'start': year[0]},
//  {
//    'start': year[1]},
//    {
//      'start': year[2]},
//      {
//        'start': year[3]},
//        {
//          'start': year[4]}
//)
console.log(testocho)

// Test 9: use test 8 years data with fixed title, allday, month, day data
// Result: Pass. commenting out start/end as causing null errors
let testnueve = []
testnueve.push({
  'title': 'Long Event',
 'allDay': true,
//  'start': new Date(year[0], month - 1, day),
// 'end': new Date(year[0], month - 1, day)
},
{
  'title': 'Long Event',
 'allDay': true,
//  'start': new Date(year[1], month - 1, day),
// 'end': new Date(year[1], month - 1, day)
},
{
  'title': 'Long Event',
 'allDay': true,
//  'start': new Date(year[2], month - 1, day),
// 'end': new Date(year[2], month - 1, day)
},
{
  'title': 'Long Event',
 'allDay': true,
//  'start': new Date(year[3], month - 1, day),
// 'end': new Date(year[3], month - 1, day)
},
{
  'title': 'Long Event',
 'allDay': true,
//  'start': new Date(year[4], month - 1, day),
// 'end': new Date(year[4], month - 1, day)
})
console.log(testnueve)


// Test 10a : loop through year data and push to array
// Result: Pass. comment out as causing errors
let testdieza = []
//console.log(year.length)
// let yl = year.length
//let i = 0
//while (i < yl) {
// console.log(year[i])
// testdieza.push(year[i])
// i += 1
//}
console.log(testdieza)

// Test 10b: loop with year data and fixed other data to get same result as test 9
// Result: Pass
let testdiezb = []
//let ylb = year.length
let j = 0
//while (j < ylb) {
//  testdiezb.push(
//    {
//      'title': 'Long Event',
//     'allDay': true,
//      'start': new Date(year[j], month - 1, day),
//     'end': new Date(year[j], month - 1, day)
//    }
//  )
//  console.log(year[j])
//  console.log(testdiezb)
//  j += 1
//}
console.log(testdiezb)


// Test 11a: month, day, year looping
// Result:Passs. resolved error with some years saving wrong data type onclick. However,  cannot read null values on anything if local storage value doesn't exist....
//let testoncemonth = []
//let testonceday = []
//let testonceyear = []

//let yk = month.length
//console.log(month.length)
//let k = 0
//while (k < yk) {
// testoncemonth.push(month[k])
// testonceday.push(day[k])
// testonceyear.push(year[k])
// k += 1
//}
//console.log(month)
//console.log(testoncemonth)
//console.log(testonceday)
//console.log(testonceyear)

// Test 11b: full loop month, day, year
// Result: Pass, but still need to refresh

//let testonceb = []

//let ylc = year.length
//let l = 0
//while (l < ylc) {
//  testonceb.push(
//    {
//      'title': 'Long Event',
 //    'allDay': true,
  //    'start': new Date(year[l], month[l] - 1, day[l]),
 //    'end': new Date(year[l], month[l] - 1, day[l])
 //   }
 // )
 // console.log(year[l])
  //console.log(testonceb)
  //l += 1
//}
//console.log(testonceb)


// Test 12: title based off title entered
// Result: Pass. but not matching the date. so need to transfer everything to handle click for the next one


// Test 13: transfer all data to handleclick
// Result: 



const MyCalendar = (props) => (

  <div>
    <Calendar
      localizer={localizer}
      events={myEventsList}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 500 }}
    />
  </div>
)

export default MyCalendar;